import React from 'react';
import AppRouter from './Router';
import { AuthProvider } from './contexts';
import './App.css'

function App() {
  
  return (
    <AuthProvider>
      <AppRouter />
    </AuthProvider>
  );
}

export default App;
