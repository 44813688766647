import React from 'react';

function Loader() {

    return (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <h2>Loading...</h2>
        </div>
    );
}

export default Loader;
